import { createContext } from 'react';

const RouteContext = createContext({
  contentKey: 0,
  changeContentKey: () => {},
  getRoutes: () => {},
  authRoutes: [],
  sidebarNavigations: [],
  permissions: []
});

export default RouteContext;
