const SET_ALL = 'SET_ALL';
const SET_AUTH_ROUTES = 'SET_AUTH_ROUTES';
const SET_SIDEBAR_NAVIGATIONS = 'SET_SIDEBAR_NAVIGATIONS';
const SET_PERMISSIONS = 'SET_PERMISSIONS';
const SET_CONTENT_KEY = 'SET_CONTENT_KEY';
const CLEAR = 'CLEAR';

const RouteReducer = (currentRouteState, action) => {
  switch (action.type) {
    case SET_ALL:
      return {
        ...currentRouteState,
        authRoutes: action.authRoutes,
        sidebarNavigations: action.sidebarNavigations,
        permissions: action.permissions
      };
    case SET_AUTH_ROUTES:
      return {
        ...currentRouteState,
        authRoutes: action.authRoutes
      };
    case SET_SIDEBAR_NAVIGATIONS:
      return {
        ...currentRouteState,
        sidebarNavigations: action.sidebarNavigations
      };
    case SET_PERMISSIONS:
      return {
        ...currentRouteState,
        permissions: action.permissions
      };
    case SET_CONTENT_KEY:
      return {
        ...currentRouteState,
        contentKey: Math.random()
      };
    case CLEAR:
      return {
        ...currentRouteState,
        authRoutes: [],
        sidebarNavigations: [],
        permissions: []
      };
    default:
      return { ...currentRouteState };
  }
};

export { RouteReducer, SET_ALL, SET_AUTH_ROUTES, SET_SIDEBAR_NAVIGATIONS, SET_PERMISSIONS, SET_CONTENT_KEY, CLEAR };
