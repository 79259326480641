import { withRouter } from 'react-router-dom';
import { useCallback, useContext, useState } from 'react';
import AuthContext from '../../context/auth/AuthContext';
import RouteContext from '../../context/route/RouteContext';
import Sidebar from './sidebar/Sidebar';
import Topbar from './topbar/Topbar';
import Content from './content/Content';

const Layout = () => {
  const { displayName, logout } = useContext(AuthContext);
  const { sidebarNavigations, authRoutes, contentKey } = useContext(RouteContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const setSidebarOpenHandler = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
  }, [sidebarOpen]);

  return (
    <div className="c-app c-default-layout">
      <Sidebar show={sidebarOpen} onShowChangeHandler={setSidebarOpenHandler} sidebarNavigations={sidebarNavigations} />
      <div className="c-wrapper">
        <Topbar showHandler={setSidebarOpenHandler} username={displayName} logoutHandler={logout} />
        <div className="c-body" key={contentKey}>
          <Content authRoutes={authRoutes} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Layout);
