import { useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ErrorContext from '../../../context/error/ErrorContext';
import useHttp, { PUT } from '../../../hooks/http/useHttp';
import Environment from '../../../environment';

const ErrorDialog = () => {
  const { errorState, dispatchErrorState } = useContext(ErrorContext);
  const { sendRequest } = useHttp();
  const [errorSaveErrorState, setErrorSaveErrorState] = useState(false);
  const [errorSaveSuccessState, setErrorSaveSuccessState] = useState(false);

  const closeErrorDialog = () => {
    dispatchErrorState({ type: 'CLEAR' });
  };

  const sendErrorAndReloadPage = () => {
    sendRequest({ url: Environment.UPDATE_ERROR(errorState.id), method: PUT })
      .then((response) => {
        if (response) {
          setErrorSaveSuccessState(true);
        } else {
          dispatchErrorState({ type: 'CLEAR' });
          setErrorSaveErrorState(true);
        }
      })
      .catch(() => {
        setErrorSaveErrorState(true);
      });
  };

  const closeErrorSaveErrorDialog = () => {
    setErrorSaveErrorState(false);
  };

  const closeErrorSaveSuccessDialog = () => {
    setErrorSaveSuccessState(false);
  };

  return (
    <>
      <Dialog header="Beklenmedik Bir Hata Oluştu" visible={errorState.hasError} style={{ width: '50vw' }} onHide={closeErrorDialog}>
        <div className="row justify-content-center">
          <h2 className="text-center">{errorState.code}</h2>
          <div className="text-center mb-2">{errorState.message}</div>
          <Button className="col-3 mr-3" label="Kapat" onClick={closeErrorDialog} />
          <Button className="col-3" label="Hatayı Bildir" onClick={sendErrorAndReloadPage} />
        </div>
      </Dialog>

      <Dialog header="Beklenmedik Bir Hata Oluştu" visible={errorSaveErrorState} style={{ width: '50vw' }} onHide={closeErrorSaveErrorDialog}>
        <div className="row">
          <div className="col-12">Hata bildirimi sırasında beklenmedik bir hata oluştu. Lütfen sistem yöneticisi ile iletişime geçiniz.</div>
          <Button className="col-4 offset-4" label="Kapat" onClick={closeErrorSaveErrorDialog} />
        </div>
      </Dialog>

      <Dialog header="Başarılı" visible={errorSaveSuccessState} style={{ width: '50vw' }} onHide={closeErrorSaveSuccessDialog}>
        <div className="row">
          <div className="col-12">Hata bildirimi başarıyla tamamlandı.</div>
          <Button className="col-4 offset-4" label="Kapat" onClick={closeErrorSaveSuccessDialog} />
        </div>
      </Dialog>
    </>
  );
};

export default ErrorDialog;
