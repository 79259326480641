import React from 'react';

const [FULL_PAGE_LOADER, CONTENT_LOADER] = ['full-page-loader-container', 'content-loader-container'];

const Loader = ({ loaderType = FULL_PAGE_LOADER, style }) => {
  return (
    <div className={loaderType} style={style}>
      <div className="loader">
        <div />
        <div />
        <div className="outside-ring">
          <div className="square" />
        </div>
        <div className="inside-ring">
          <div className="square" />
        </div>
      </div>
    </div>
  );
};

export default Loader;

export { FULL_PAGE_LOADER, CONTENT_LOADER };
