import { lazy } from 'react';

const Login = lazy(() => import('../../../Account/Login/Login'));
const ClientHomePage = lazy(() => import('../../../ClientHomePage/ClientHomePage'));
const NotFound = lazy(() => import('../../../Error/404/NotFound'));
const InternalServerError = lazy(() => import('../../../Error/503/ServiceUnavailable'));

const NonAuthPages = {
  Login: { exact: true, path: '/giris', component: Login },
  ClientHomePage: { exact: true, path: '/', component: ClientHomePage },
  Page404: { exact: true, path: '/sayfa-bulunamadi', component: NotFound },
  Page503: { exact: true, path: '/sunucu-hatasi', component: InternalServerError }
};

export default NonAuthPages;
