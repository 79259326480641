import { lazy } from 'react';

const HomePage = lazy(() => import('../../../../shared/components/home/HomePages'));

const HomePages = {
  HomePage: {
    path: '/panel',
    exact: true,
    component: HomePage,
    pageName: 'Anasayfa',
    groupName: 'Menü',
    icon: null,
    showSidebar: false
  }
};

export default HomePages;
