import React, { useReducer } from 'react';
import ErrorContext from './ErrorContext';
import ErrorReducer from './ErrorReducer';

const ErrorProvider = ({ children }) => {
  const [errorState, dispatchErrorState] = useReducer(ErrorReducer, { hasError: false, message: null, code: null, id: null }, undefined);
  return <ErrorContext.Provider value={{ errorState, dispatchErrorState }}>{children}</ErrorContext.Provider>;
};

export default ErrorProvider;
