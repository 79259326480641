import { Route, Switch } from 'react-router-dom';
import { CContainer } from '@coreui/react';

const Content = ({ authRoutes }) => {
  const userRoutesRender = authRoutes.map((route, idx) => <Route exact={route.exact} path={route.path} component={route.component} key={idx} />);

  return (
    <main className="c-main">
      <CContainer fluid className="pl-0 pr-0">
        <Switch>{userRoutesRender}</Switch>
      </CContainer>
    </main>
  );
};

export default Content;
