import React from 'react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CImg } from '@coreui/react';
import AvatarPicture from '../../../assets/img/avatar.png';

const TopbarProfile = ({ username, logoutHandler }) => {
  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg src={AvatarPicture} height={25} className="c-avatar-img" />
        </div>
        <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0 topbar-profile-dropdown" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Hesap</strong>
        </CDropdownItem>
        <CDropdownItem onClick={logoutHandler}>
          <i className="bx bx-log-out font-size-16 align-middle me-3 text-danger" />
          Çıkış
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TopbarProfile;
