import { lazy } from 'react';

const UserTable = lazy(() => import('../../../Admin/User/UserTable'));
const UserCreate = lazy(() => import('../../../Admin/User/UserFormCreate'));
const UserEdit = lazy(() => import('../../../Admin/User/UserEdit'));
const UserEditPassword = lazy(() => import('../../../Admin/User/UserEditPassword'));
const SubjectTable = lazy(() => import('../../../Admin/Subject/SubjectTable'));
const SubjectCreate = lazy(() => import('../../../Admin/Subject/SubjectFormCreate'));
const SubjectEdit = lazy(() => import('../../../Admin/Subject/SubjectEdit'));
const AppointmentTable = lazy(() => import('../../../Admin/AppointmentListing/AppointmentTable'));
const AdminPages = {
  UserTable: {
    path: '/panel/admin/kullanicilar',
    exact: true,
    component: UserTable,
    pageName: 'Kullanıcı Bilgileri',
    groupName: 'Admin',
    showSidebar: true
  },
  UserCreate: {
    path: null,
    exact: false,
    component: UserCreate,
    pageName: 'Kullanıcı Bilgileri Oluşturma',
    groupName: null,
    icon: null,
    showSidebar: false
  },
  UserEditPassword: {
    path: null,
    exact: false,
    component: UserEditPassword,
    pageName: 'Şifre Değiştirme',
    groupName: null,
    icon: null,
    showSidebar: false
  },
  UserEdit: {
    path: null,
    exact: false,
    component: UserEdit,
    pageName: 'Kullanıcı Düzenleme',
    groupName: null,
    icon: null,
    showSidebar: false
  },
  UserDelete: {
    path: null,
    exact: false,
    component: null,
    pageName: 'Kullanıcı Silme',
    groupName: null,
    icon: null,
    showSidebar: false
  },
  SubjectTable: {
    path: '/panel/admin/konular',
    exact: true,
    component: SubjectTable,
    pageName: 'Konu Başlıkları',
    groupName: 'Admin',
    showSidebar: true
  },
  SubjectCreate: {
    path: null,
    exact: false,
    component: SubjectCreate,
    pageName: 'Konu Başlığı Ekle',
    groupName: null,
    icon: null,
    showSidebar: false
  },
  SubjectEdit: {
    path: null,
    exact: false,
    component: SubjectEdit,
    pageName: 'Konu Başlığı Düzenleme',
    groupName: null,
    icon: null,
    showSidebar: false
  },
  SubjectDelete: {
    path: null,
    exact: false,
    component: null,
    pageName: 'Konu Başlığı Silme',
    groupName: null,
    icon: null,
    showSidebar: false
  },
  AppointmentTable: {
    path: '/panel/admin/randevular',
    exact: true,
    component: AppointmentTable,
    pageName: 'Randevular',
    groupName: 'Admin',
    showSidebar: true
  }
};

export default AdminPages;
