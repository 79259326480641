import logoColor from './assets/img/logo-color.svg';
import logoTransparent from './assets/img/logo_transparent.svg';
import video from './assets/img/akademik.mp4';

const faq = {
  title: 'SIK SORULAN SORULAR',
  questions: [
    {
      question: 'Online Görüşme Ücreti Ne Kadar?',
      answer: 'Hukuki danışmanlık ücretleri, seçilen konu başlığına göre değişiklik göstermektedir.'
    },
    {
      question: 'Online Danışmanlık Sistemi Nedir?',
      answer: 'Danışmanlık hizmeti isteyen herkese daha hızlı yanıt ve daha hızlı çözüm bulmak için yapılmış bir sistemdir.'
    },
    {
      question: 'Bilgisayar, Telefon ya da Tablet ile Görüşmeye Katılabilir Miyim?',
      answer: 'Evet. İnternete bağlı cihazlar ile seçilen seçeneklere göre katılım sağlamanız mümkündür.'
    },
    {
      question: 'Danışmana Nasıl Ulaşacağım?',
      answer:
        'Randevu aldıktan sonra danışmanlarımıza(avukatlarımıza) bildirimler gitmektedir. Size randevu tarihi olarak seçtiğiniz tarihde dönüş yapılmış olacaktır.'
    },
    {
      question: 'Danışmanıma Belgelerimi Nasıl Göndereceğim?',
      answer: 'Randevu oluştururken yüklediğiniz dokümanları danışmanlarınız görecektir (KVVK kapsamında).'
    },
    {
      question: 'Randevu İptalinde Ücret İadesi Yapılır Mı?',
      answer:
        '1 gün öncesine kadar yapılan iptallerde ödemeler iade edilecektir. Ödeme yaptığınız bankanıza bağlı olarak birkaç gün içerisinde ödeme yaptığınız hesaba ücret iadeniz yansıtılacaktır.'
    },
    {
      question: 'Danışmanlık Sisteminde Kişisel Bilgilerim Güvende Mi?',
      answer:
        'Bu platform üzerindeki görüşmeleriniz kesinlikle kaydedilmez. Mesajlarınız da şifreleme yöntemiyle kilitli şekilde korunur. Kendinize dair verdiğiniz bilgiler ve/veya ilgili hukuki danışmanlık hizmeti kapsamında gönderdiğiniz belgeler KVVK ya uygun olarak saklanmaktadır.'
    },
    { question: 'Danışma sonrası hizmet almaya karar verdim. Ücretten mahsup edilir mi?', answer: 'Evet' },
    {
      question: 'Danışmanlık Sistemi Hakkında Daha Detaylı Sorular İçin İletişim Numarası Var Mı?',
      answer:
        'Bu aşamalardan herhangi birinde hata alırsanız veya sorun yaşarsanız 0538 873 08 96 / 0546 897 98 16 / 0507 906 87 33 telefon numarasını arayarak veya akademikhdb@gmail.com / docentlikbasvurusu@gmail.com adresine mail atarak yardım alabilirsiniz.'
    }
  ]
};

const appPort = 8080;

const informationUrl = 'https://www.akademikhukuk.org/iletisim/';

const configAkademikHukuk = { faq, logoColor, logoTransparent, appPort, informationUrl, video };

export default configAkademikHukuk;
