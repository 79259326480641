import NonAuthPages from './Pages/NonAuthPages';
import AdminPages from './Pages/AdminPages';
import HomePages from './Pages/HomePages';

const NonAuthPageComponents = NonAuthPages;
const AuthPageComponents = {
  ...HomePages,
  ...AdminPages
};
const GroupIcons = {
  Admin: {
    sidebarName: 'Admin'
  }
};

export { AuthPageComponents, NonAuthPageComponents, GroupIcons };
