import { useContext, useCallback } from 'react';
import { CCreateElement, CSidebar, CSidebarBrand, CSidebarNav, CSidebarNavTitle, CSidebarNavDropdown, CSidebarNavItem } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { logoTransparent } from '../../../config/config';
import RouteContext from '../../../context/route/RouteContext';

const Sidebar = ({ show, onShowChangeHandler, sidebarNavigations }) => {
  const { changeContentKey } = useContext(RouteContext);

  const changeContentKeyHandler = useCallback(
    (e) => {
      if (e.target.className !== 'c-sidebar-nav-dropdown-toggle') {
        if (e.target.parentElement.className !== 'c-sidebar-nav-dropdown-toggle' && e.target.parentElement.className !== 'side-root-group') {
          changeContentKey();
        }
      }
    },
    [changeContentKey]
  );
  return (
    <CSidebar show={show} minimize={false} onShowChange={onShowChangeHandler} colorScheme="light">
      <CSidebarBrand className="d-md-down-none pt-4" to="/panel">
        {<CIcon src={logoTransparent} className="c-sidebar-brand-full" height={80} />}
        {<CIcon src={logoTransparent} className="c-sidebar-brand-minimized" height={80} />}
      </CSidebarBrand>
      <CSidebarNav onClick={changeContentKeyHandler}>
        <CCreateElement
          items={sidebarNavigations}
          components={{
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
    </CSidebar>
  );
};

export default Sidebar;
